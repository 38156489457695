<template>
  <div class="time-page page">
    <div class="container">
      <div class="title">Выберите время</div>
      <div class="time-form">
        <van-picker title="Title" :columns="columns" @change="onChange" />
      </div>
      <div class="time-form__footer">
        <van-button
          type="primary"
          class="time-page__footer"
          round
          @click="submit"
          >Сохранить</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from "axios";

export default {
  name: "Time",
  data() {
    return {
      select: [],
    };
  },
  computed: {
    columns() {
      return [];
    },
  },
  methods: {
    onPushTime() {
      let TimeHours = [];
      let TimeMinutes = [];
      this.columns.push({
        values: TimeHours,
        defaultIndex: 20,
      });

      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          TimeHours.push("0" + i);
        } else {
          TimeHours.push("" + i);
        }
      }
      this.columns.push({
        values: TimeMinutes,
        defaultIndex: 0,
      });

      for (let i = 0; i < 60; i++) {
        if (i % 5 === 0) {
          if (i < 10) {
            TimeMinutes.push("0" + i);
          } else {
            TimeMinutes.push("" + i);
          }
        }
      }

      this.select = [TimeHours[0], TimeMinutes[0]];
    },

    onChange(picker, value) {
      console.log(value);
      this.select = value;
    },
    submit() {
      // const data = {
      //   token: 'token1',
      //   notification_time: this.select,
      // }

      axios
        .put(
          `https://hm.tgapp.live/self?token=${this.$route.query.token}&&notification_time=${this.select}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
          }
        )
        .then(() => {
          console.log(this.select);
          let tg = window.Telegram.WebApp;
          tg.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  created() {
    this.onPushTime();
    this.select = ["20", "00"];
  },
};
</script>

<style scoped lang="scss">
.title {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 32px;
}
.time-page {
  padding: 60px 20px 45px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  &__footer {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    width: 263px;
  }
}
.time-form {
  // height: 136px;
  // margin: 16px 0;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  &__footer {
    display: flex;
    justify-content: center;
  }
  &__item {
    background: #ffffff;
    border: 2px solid #c9c9c9;
    border-radius: 16px;
    width: 100px;
    height: 74px;

    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 26px;
    /* or 72% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #c6b3a3;
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}
.van-button--primary {
  background: #000;
  border-color: #000;
  color: #fff;
  max-width: 309px;
  width: 100%;
  text-align: center;
  justify-content: center;
}
</style>
